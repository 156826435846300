const i18n = {
	en: {
		'home.title': 'The art of sound to harmonize your creations.',
		'presentation.title': 'Presentation',
		'presentation.1': 'Our name “LabChime” comes from our identity. Our workshop is a laboratory (Lab) whose goal is to create, develop, manufacture and qualify watch gongs.<br>The term “Chime” comes from English and refers to the sound of a carillon.',
		'presentation.2': 'Like a carillon bell, which has three bells, our know-how is based on three main axes: innovation, manufacturing, qualification.',
		'skills.title': 'Skills',
		'skills.1': 'Traditional know-how combined with current manufacturing, control and engineering techniques allow us to imagine, design and produce single-thread, two-threads gongs and chime gongs. In addition, we have the audacity to explore disruptive solutions in terms of sonorous elements.',
		'skills.2': 'Our goal is to work for established watch brands, but also with those wishing to break the codes.',
		'skills.3': 'We work in the field of gong restoration of both modern and old pieces. We we also collaborate with museums, as well as independent watchmakers.',
		'laboratory.title': 'The laboratory',
		'laboratory.1': "LabChime Sàrl is a watch gong manufacturing workshop. The gongs are made manually, piece by piece.<br>Today's demands inspire us to offer you the essence of craftmanship, associated with strict criteria of quality and reproducibility.",
		'laboratory.2': 'LabChime Sàrl builds all quality control processes according to your needs. From the range of manufacturing until final sound control after tuning. You will have the assurance of compliance with your required criteria.',
		'contact.title': 'Contact us',
		'contact.1': 'As part of a possible partnership or any interest, we would be happy to get in touch!'
	},
	fr: {
		'home.title': 'L’art du son pour harmoniser vos créations.',
		'presentation.title': 'Présentation',
		'presentation.1': 'Notre nom « LabChime » tire son origine de notre identité. Notre atelier est un laboratoire (Lab) dont le but est de créer, élaborer, fabriquer et qualifier des timbres horlogers.<br>Le terme « Chime » vient de l’anglais et désigne le nom « carillon ».',
		'presentation.2': 'À la manière d’un timbre de carillon, qui comporte trois timbres, notre savoir-faire s’articule sur trois axes principaux : l’innovation, la fabrication, la qualification.',
		'skills.title': 'Compétences',
		'skills.1': 'Un savoir-faire traditionnel allié aux techniques de fabrication, de contrôle et d’ingénieurerie actuelles nous permettent d’imaginer, de concevoir et de concrétiser des timbres mono-file, bi-files et carillon. De plus, nous avons l’audace d’explorer des solutions disruptives en matière d’éléments sonores.',
		'skills.2': 'Notre objectif est de travailler pour les marques horlogères établies, mais aussi avec celles désireuses de briser les codes.',
		'skills.3': 'Nous intervenons dans le cadre de restauration de timbre de pièces modernes comme anciennes. Nous collaborons également avec des musées, ainsi que des horlogers indépendants.',
		'laboratory.title': 'Le laboratoire',
		'laboratory.1': 'LabChime Sàrl est un atelier de fabrication de timbres horlogers. La confection des timbres est faite manuellement, à la pièce. Les exigences d’aujourd’hui nous inspire à vous proposer l’essence d’un travail artisanal, associé à des critères stricts de qualité et de reproductibilité.',
		'laboratory.2': 'LabChime Sàrl édifie l’ensemble des process de contrôle qualité selon vos besoins. De la gamme de fabrication jusqu’au contrôle sonore finale après accordage. Vous aurez l’assurance du respect de vos critères exigés.',
		'contact.title': 'Nous contacter',
		'contact.1': 'Dans le cadre d’un éventuel partenariat ou d’un intérêt quelconque, nous serions heureux de pouvoir vous renseigner et vous aider !'
	}
};

// Translations

const translatables = document.querySelectorAll('[data-i18n]');
const query         = window.location.search;
const params        = new URLSearchParams(query);
const lang          = params.get('lang');
if (lang && lang in i18n) {
	document.querySelector('meta[name="description"]')
		.setAttribute('content', i18n[lang]['home.title']);
	for (const translatable of translatables) {
		const id = translatable.dataset.i18n;
		translatable.innerHTML = i18n[lang][id];
	}
}

// Animations

const UI = {
	presentation: document.getElementById('presentation'),
	competences:  document.getElementById('competences'),
	laboratoire:  document.getElementById('laboratoire'),
};
const intersectionObserver = new IntersectionObserver((entries) => {
	entries.forEach(entry => {
		if (!entry.isIntersecting) return;
		const element = entry.target;
		if (element.classList.contains('left')) {
			element.classList.add('slide-in-left');
		} else {
			element.classList.add('slide-in-right');
		}
		intersectionObserver.unobserve(element);
	});
});
intersectionObserver.observe(UI.presentation);
intersectionObserver.observe(UI.competences);
intersectionObserver.observe(UI.laboratoire);